import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

function BlogPost({ post, filter, setFilter }) {
  return (
    <div
      key={post.id}
      className="flex duration-300 ease-in-out transform bg-white rounded-lg shadow hover:shadow-xl "
    >
      <div className="flex flex-col flex-1 overflow-hidden rounded-lg shadow-sm">
        <Link
          to={`https://www.frontlineremovals.com.au/blog/${post.slug.current}/`}
          className="focus:outline-none group"
        >
          {post.mainImage && (
            <GatsbyImage
              fluid={post.mainImage.asset.fluid}
              className="object-cover w-full h-64"
              alt={`Blog post - ${post.title}`}
            />
          )}
          <div className="px-6 mt-4">
            <h3
              title={post.title}
              className="text-3xl leading-tight line-clamp-1 text-brand-blue font-display group-focus:underline"
            >
              {post.title}
            </h3>
            <div className="mt-4">
              <p title={post.description} className="line-clamp-3">
                {post.description}
              </p>
            </div>
          </div>
        </Link>
        <p className="px-6 pt-2 my-4 text-gray-600">
          <time>{post._createdAt}</time>
        </p>
        <div className="flex flex-wrap items-start flex-1 px-6 mt-auto">
          <div className="py-4">
            {post.categories.map((category) =>
              setFilter ? (
                <button
                  type="button"
                  onClick={() =>
                    filter === category.title
                      ? setFilter('all')
                      : setFilter(category.title)}
                  key={category.title}
                  className={`inline-block px-3 py-1 mt-4 mr-2 text-sm font-semibold text-white transition duration-150 ease-in-out rounded hover:bg-brand-orange focus:outline-none focus:ring focus:bg-brand-orange ${
                    filter === category.title
                      ? 'bg-brand-orange'
                      : 'bg-brand-blue'
                  }`}
                >
                  #{category.title}
                </button>
              ) : (
                <button
                  type="button"
                  key={category.title}
                  className={`inline-block px-3 py-1 mt-4 mr-2 text-sm font-semibold text-white transition duration-150 ease-in-out rounded hover:bg-brand-orange focus:outline-none focus:ring focus:bg-brand-orange ${
                    filter === category.title
                      ? 'bg-brand-orange'
                      : 'bg-brand-blue'
                  }`}
                >
                  #{category.title}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

BlogPost.propTypes = {
  post: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
};

export { BlogPost };
